import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import StyledButton from "../../styledButton";
import StyledCheck from "../../styledCheck";
import styles from "./index.module.scss";
import { useWindowDimensions } from "../../../lib/helpers";

const modalStyles = {
    overlay: {
        cursor: "pointer",
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,0.3)",
        zIndex: 40,
        alignItems: "center",
    },
    content: {
        display: "block",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "transparent",
        top: 50,
        right: 0,
        bottom: 0,
        left: 0,
        position: "absolute",
        height: 730,
    },
    header: {
        color: "red",
    },
};

const EmailSupplierModal = (props) => {
    const { searchResults = [], emailOpen, setEmailOpen, hasWixParent } = props;
    // console.log("EmailSupplierModal searchResults", searchResults);
    const [selectedEmails, setSelectedEmails] = useState([]);
    const { height, width } = useWindowDimensions();
    const { t } = useTranslation();
    Modal.setAppElement("#root");
    modalStyles["content"]["top"] = width <= 500 ? 0 : 50;
    modalStyles["content"]["height"] = width <= 500 ? 657 : 730;
    return (
        <Modal
            isOpen={emailOpen}
            onRequestClose={(a) => {
                setEmailOpen(false);
            }}
            style={modalStyles}
        >
            <div className={styles["modal"]}>
                <span className={styles["close-x"]} onClick={() => setEmailOpen(false)}></span>
                <div className={styles["modal-content"]}>
                    <h4>{t("Select companies to email")}</h4>
                    <ul className={styles["company-list"]}>
                        {searchResults.map((searchResult) => (
                            <li key={searchResult.id}>
                                <StyledCheck
                                    id={searchResult.id}
                                    checked={selectedEmails.includes(searchResult.id)}
                                    label={`${searchResult.name} (${searchResult.city})`}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectedEmails([...selectedEmails, searchResult.id]);
                                        } else {
                                            setSelectedEmails(
                                                selectedEmails.filter(
                                                    (selectedEmail) =>
                                                        selectedEmail !== searchResult.id,
                                                ),
                                            );
                                        }
                                    }}
                                />
                            </li>
                        ))}
                    </ul>
                    <div className={styles["modal-footer"]}>
                        {!hasWixParent && (
                            <a
                                href={`mailto:${searchResults
                                    .filter((searchResult) =>
                                        selectedEmails.includes(searchResult.id),
                                    )
                                    .map((searchResult) => searchResult.salesContactEmail)
                                    .join(",")}?subject=Inquiry%20from%20FSC`}
                            >
                                <StyledButton disabled={!selectedEmails.length}>
                                    {t("Compose Message")}
                                </StyledButton>
                            </a>
                        )}
                        {hasWixParent && (
                            <StyledButton
                                disabled={!selectedEmails.length}
                                onClick={() => {
                                    window.parent.postMessage(
                                        {
                                            mailto: `${searchResults
                                                .filter((searchResult) =>
                                                    selectedEmails.includes(searchResult.id),
                                                )
                                                .map(
                                                    (searchResult) =>
                                                        searchResult.salesContactEmail,
                                                )
                                                .join(",")}?subject=Inquiry%20from%20FSC`,
                                        },
                                        "*",
                                    );
                                }}
                            >
                                {t("Compose Message")}
                            </StyledButton>
                        )}
                        <p className={styles["footnote"]}>
                            {t(
                                "This will open up your email client with all selected companies as recipients",
                            )}
                        </p>
                    </div>
                </div>
            </div>
            )}
        </Modal>
    );
};
export default EmailSupplierModal;

import type { SectionsType, ProductsType } from "../types";
import { useState, useEffect } from "react";
import config from "../config";

export const buildHeaders = () => {
    return {
        Accept: "application/json",
        "Content-Type": "application/json",
    };
};

export const getProductOptions = (sections: SectionsType, searchTerms, t: Function) => {
    let productOptions: number[] = [];
    const { selectedProducts } = searchTerms;
    if (sections.length > 0) {
        sections.forEach((section) => {
            section.categories.forEach((category) => {
                category.products.forEach((product) => {
                    const isAlreadySelected = (sp) => {
                        return sp === product.id;
                    };
                    const isAvailableOption =
                        selectedProducts.filter(isAlreadySelected).length === 0;
                    if (isAvailableOption) {
                        let productOption =
                            t(section.name) + " > " + t(category.name) + " > " + t(product.name);
                        productOptions.push({
                            value: product.id,
                            label: productOption,
                        });
                    }
                });
            });
        });
    }
    return productOptions;
};

export const getProductList = (sections: SectionsType, t: (string) => string) => {
    let productList: ProductsType = [];
    if (sections.length > 0) {
        sections.forEach((section) => {
            section.categories.forEach((category) => {
                category.products.forEach((product) => {
                    if (
                        productList.filter((arg) => {
                            return product.id === arg.id;
                        }).length === 0
                    ) {
                        productList.push({
                            id: product.id,
                            name: product.name,
                        });
                    }
                });
            });
        });
    }
    return productList;
};

export const getProductNameById = (id: number, productList: ProductsType) => {
    const product: ProductType | typeof undefined = productList.find((product) => {
        return id === product.id;
    });
    return product ? product.name : "";
};

export const getUrlParams = (search) => {
    const queries = search.slice(search.indexOf("?") + 1).split("&");
    let params = {};
    queries.forEach((query) => {
        let [key, val] = query.split("=");
        params[key] = decodeURIComponent(val);
    });
    return params;
};

export const updateQueryStringParam = (key, value) => {
    var baseUrl = [
            window.location.protocol,
            "//",
            window.location.host,
            window.location.pathname,
        ].join(""),
        urlQueryString = document.location.search,
        newParam = key + "=" + value,
        params = "?" + newParam;

    // If the "search" string exists, then build params from it
    if (urlQueryString) {
        var updateRegex = new RegExp("([?&])" + key + "[^&]*");
        var removeRegex = new RegExp("([?&])" + key + "=[^&;]+[&;]?");

        if (typeof value === "undefined" || value === null || value === "") {
            // Remove param if value is empty
            params = urlQueryString.replace(removeRegex, "$1");
            params = params.replace(/[&;]$/, "");
        } else if (urlQueryString.match(updateRegex) !== null) {
            // If param exists already, update it
            params = urlQueryString.replace(updateRegex, "$1" + newParam);
        } else {
            // Otherwise, add it to end of query string
            params = urlQueryString + "&" + newParam;
        }
    }

    // no parameter was set so we don't need the question mark
    params = params === "?" ? "" : params;

    window.history.replaceState({}, "", baseUrl + params);
};

// Custom Hook Code
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
};
// End Custom Hook Code

export const getSearchResults = async (officialSearchTerms, affiliate) => {
    const searchResult = await fetch(`${config.apiRoot}/search`, {
        method: "POST",
        headers: buildHeaders(),
        body: JSON.stringify(officialSearchTerms),
    });
    const searchJSON = await searchResult.json();
    // console.log("search - officialSearchTerms.fromSearchUID: ", officialSearchTerms.fromSearchUID);
    if (officialSearchTerms.fromSearchUID !== true) {
        console.log(
            " posting to search-request based on fromSearchUID being falsey: ",
            officialSearchTerms.fromSearchUID,
        );
        const resultSearchRequest = await fetch(`${config.apiRoot}/search-request`, {
            method: "POST",
            headers: buildHeaders(),
            body: JSON.stringify({
                ...officialSearchTerms,
                shortName: affiliate,
                locations: searchJSON.map((location) => location.id),
            }),
        });
        if (resultSearchRequest.status === 200) {
            const jsonSearchRequest = await resultSearchRequest.json();

            if (affiliate !== "buyer-portal") {
                // console.log(
                //     "search-filter: affiliate was not buyer-portal, thus we expect iframe",
                // );
                // console.log("search-filter: about to postMessage to change search");
                window.parent.postMessage({ search: jsonSearchRequest.uuid }, "*");
            } else {
                // console.log("updating search address bar for non-iframe implementation");
                updateQueryStringParam("search", jsonSearchRequest.uuid);
            }
        } else {
            console.log("Search Request did not save.");
        }
    } else {
        // console.log("did not post to search-request because fromSearchUID was true");
    }
    // console.log("helpers.js: getSearchResults", searchJSON);
    return searchJSON;
};

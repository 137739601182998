import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import styles from "./index.module.scss";
import { CopyToClipboard } from "react-copy-to-clipboard";
import StyledButton from "../../styledButton";

const modalStyles = {
    overlay: {
        cursor: "pointer",
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,0.3)",
        zIndex: 40,
        alignItems: "center",
    },
    content: {
        display: "block",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "transparent",
        top: 50,
        right: 0,
        bottom: 0,
        left: 0,
        position: "absolute",
    },
    header: {
        color: "red",
    },
};

const ShareModal = (props) => {
    const { shareOpen, setShareOpen, affiliate, searchUUID, parentURL } = props;

    const [copySuccess, setCopySuccess] = useState(false);
    // const textAreaRef = useRef(null);
    const { t } = useTranslation();

    Modal.setAppElement("#root");

    const turnOffCopied = () => {
        setCopySuccess(false);
    };

    // const copyToClipboard = (e) => {
    //     textAreaRef.current.select();
    //     document.execCommand("copy");
    //     e.target.focus();
    //     setCopySuccess(true);
    //     setTimeout(turnOffCopied, 2000);
    // };

    let url = "";
    if (affiliate === "buildwithfsc.com") {
        url = `https://buildwithfsc.com/sourcing?search=${searchUUID}`;
    } else {
        url = `${parentURL}?search=${searchUUID}`;
    }

    return (
        <Modal
            isOpen={shareOpen}
            onRequestClose={(a) => {
                setShareOpen(false);
            }}
            style={modalStyles}
        >
            <div className={styles["modal"]}>
                <span className={styles["close-x"]} onClick={() => setShareOpen(false)}></span>
                <div className={styles["modal-content"]}>
                    <h4>{t("Share")}</h4>
                    <p>&nbsp;</p>
                    <p>
                        <textarea value={url} onChange={() => {}} />
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <CopyToClipboard
                            text={url}
                            onCopy={() => {
                                setCopySuccess(true);
                                setTimeout(turnOffCopied, 2000);
                            }}
                        >
                            <StyledButton>{t("Copy to Clipboard")}</StyledButton>
                        </CopyToClipboard>
                    </p>
                    <p>&nbsp;</p>

                    <p>{copySuccess && <span className={styles.copied}>Copied!</span>}&nbsp;</p>
                </div>
            </div>
        </Modal>
    );
};
export default ShareModal;

// @flow
import React, { useState, useEffect } from "react";
import type { LocationType } from "../../../types";
import FSCLogo from "../../../assets/images/header_FSClogo_25px_wide.png";
import searchIcon from "../../../assets/images/search.png";
import cancelIcon from "../../../assets/images/x-mark.png";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

type Props = { location: LocationType, searchTerms: Object, hasWixParent: boolean };

const Location = (props: Props) => {
    const { location, searchTerms, selectedResult, setSelectedResult, hasWixParent } = props;
    const [distanceText, setDistanceText] = useState("");
    const [clicked, setClicked] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (selectedResult !== location.id) {
            setClicked(false);
        }
    }, [selectedResult, location.id]);

    useEffect(() => {
        if (searchTerms && location && location.distance) {
            let tempDistanceText = "";
            if (searchTerms.postalCodeCountry) {
                const units: string = searchTerms.postalCodeCountry === "US" ? "mi" : "km";
                const conversion: number = units === "mi" ? 0.6213711922 : 1;
                tempDistanceText = location.distance
                    ? Math.round((location.distance / 1000) * conversion)
                          .toString()
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
                      " " +
                      units +
                      " from " +
                      searchTerms.postalCode
                    : "";
            } else {
                tempDistanceText = "";
            }
            setDistanceText(tempDistanceText);
        }
    }, [searchTerms, location]);

    return (
        <li
            key={location.id}
            className={`${styles["location"]} ${location.fscMember ? styles.fscMember : ""}`}
        >
            <span
                className={`${styles["result-number"]} ${clicked ? styles.clicked : ""}`}
                onMouseEnter={() => setSelectedResult(location.id)}
                onMouseLeave={() => !clicked && setSelectedResult(undefined)}
                onClick={() => {
                    setClicked(!clicked);
                    setSelectedResult(location.id);
                }}
            >
                {!clicked && (
                    <>
                        <img src={searchIcon} alt={t("show")}></img>
                    </>
                )}
                {clicked && (
                    <>
                        <img src={cancelIcon} alt={t("hide")}></img>
                    </>
                )}
            </span>
            {distanceText && searchTerms.postalCodeCountry && (
                <p className={styles.proximity}>{distanceText}</p>
            )}
            {location.fscMember && (
                <>
                    <p className={styles["fsc-certified-label"]}>{t("FSC Member")}</p>
                    <img
                        className={styles["fsc-certified"]}
                        alt="FSC Logo denoting membership"
                        src={FSCLogo}
                    />
                </>
            )}

            <h2>{location.name}</h2>
            <h3>{location.title}</h3>
            {Boolean(location.products.length) && (
                <ul className={styles["card-products"]}>
                    {location.products.map((product) => (
                        <li key={product.id}>
                            <p className={styles["product-name"]}>{t(product.name)}</p>
                            <p className={styles.availability}>{t(product.availability)}</p>
                        </li>
                    ))}
                </ul>
            )}
            {Boolean(location.species.length) && (
                <p className={styles["supplier-types"]}>
                    <span className={styles["supplier-types-label"]}>{t("Species")}: </span>
                    {location.species}
                </p>
            )}

            {Boolean(location.supplierTypes.length && location.supplierTypes.includes(",")) && (
                <p className={styles["supplier-types"]}>
                    <span className={styles["supplier-types-label"]}>{t("Supplier Types")}: </span>
                    {location.supplierTypes}
                </p>
            )}
            {Boolean(location.supplierTypes.length && !location.supplierTypes.includes(",")) && (
                <p className={styles["supplier-types"]}>
                    <span className={styles["supplier-types-label"]}>{t("Supplier Type")}: </span>
                    {location.supplierTypes}
                </p>
            )}

            <p className={styles["contact-name"]}>
                {location.salesContactName || location.contactName}
            </p>
            <p className={styles["contact-number"]}>
                {location.salesContactPhone || location.contactPhone}
            </p>
            <div className={styles.links}>
                {!hasWixParent && (
                    <a href={`mailto:${location.salesContactEmail || location.contactEmail}`}>
                        {t("Email")}
                    </a>
                )}
                {hasWixParent && (
                    <button
                        className="text-link"
                        onClick={() => {
                            window.parent.postMessage(
                                {
                                    mailto: location.contactEmail,
                                },
                                "*",
                            );
                        }}
                    >
                        {t("Email")}
                    </button>
                )}
                {location.webAddress && (
                    <>
                        <span className={styles.divider}></span>
                        {!hasWixParent && <a href={location.webAddress}>{t("Website")}</a>}
                        {hasWixParent && (
                            <button
                                className="text-link"
                                onClick={() => {
                                    window.parent.postMessage(
                                        {
                                            externalSite: location.webAddress,
                                        },
                                        "*",
                                    );
                                }}
                            >
                                {t("Website")}
                            </button>
                        )}
                    </>
                )}
            </div>
            <div className={styles.printLinks}>
                <p>{location.contactEmail}</p>
                <span className={styles.divider}></span>
                <p>{location.webAddress}</p>
            </div>
            <p className={styles.address}>
                {location.streetAddress1}
                {location.streetAddress2 && (
                    <>
                        <br /> {location.streetAddress2}
                    </>
                )}
                <br />
                {location.city}, {location.provinceOrState} {location.postalCode} {location.country}
            </p>
            <span className={styles["certified-since"]}>
                {t("Certified since")} {location.certifiedSince}
            </span>
        </li>
    );
};

export default Location;

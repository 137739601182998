import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import Map from "../map";

import styles from "./index.module.scss";
import SearchFilter from "../search-filter";
import LocationList from "../location-list";
import PrintLocationList from "../print-location-list";

const Search = (props) => {
    const {
        affiliate,
        setCurrentExtent,
        setPage,
        setSearchTerms,
        searchResults,
        searchTerms,
        speciesList,
        isFetchingSearchResults,
        setIsFetchingSearchResults,
        sections,
        setSearchResults,
        currentExtent,
        page,
        hasWixParent,
        searchUUID,
        setCurrentSearchUUID,
        showFilters,
        setShowFilters,
        searchCenter,
        setSearchCenter,
    } = props;
    const { t } = useTranslation();

    const [isMoving, setIsMoving] = useState(false);
    const [isZooming, setIsZooming] = useState(false);
    const [selectedResult, setSelectedResult] = useState();
    const [mapInteracted, setMapInteracted] = useState(false);
    const mapRef = useRef({});

    useEffect(() => {
        if (showFilters) {
            document.body.classList.add("show-filters");
        } else {
            document.body.classList.remove("show-filters");
        }
        return () => document.body.classList.remove("show-filters");
    }, [showFilters]);

    useEffect(() => {
        setPage(0);
    }, [searchResults, setPage]);

    return (
        <>
            <div className={styles["filter-toggle"]} onClick={() => setShowFilters(!showFilters)}>
                <span className={styles["chevron"]}></span>
                {!showFilters && <>{t("Show Filters")}</>}
                {showFilters && <>{t("Hide Filters")}</>}
            </div>
            <div className={styles["content"]}>
                <div
                    id={styles["search-results-content"]}
                    // className={`${showFilters ? styles["show-filters"] : ""}`}
                >
                    <div className={styles.content}>
                        <SearchFilter
                            affiliate={affiliate}
                            searchTerms={searchTerms}
                            setSearchTerms={setSearchTerms}
                            isFetchingSearchResults={isFetchingSearchResults}
                            sections={sections}
                            setIsFetchingSearchResults={setIsFetchingSearchResults}
                            setSearchResults={setSearchResults}
                            searchResults={searchResults}
                            speciesList={speciesList}
                            setCurrentSearchUUID={setCurrentSearchUUID}
                            searchUUID={searchUUID}
                            showFilters={showFilters}
                            setSearchCenter={setSearchCenter}
                        />
                        <div className={styles["results"]}>
                            <div className={styles["map"]}>
                                <Map
                                    setMapInteracted={setMapInteracted}
                                    searchResults={searchResults}
                                    setCurrentExtent={setCurrentExtent}
                                    setPage={setPage}
                                    isMoving={isMoving}
                                    setIsMoving={setIsMoving}
                                    isZooming={isZooming}
                                    setIsZooming={setIsZooming}
                                    selectedResult={selectedResult}
                                    page={page}
                                    searchTerms={searchTerms}
                                    searchCenter={searchCenter}
                                    mapRef={mapRef}
                                />
                            </div>
                            <LocationList
                                mapInteracted={mapInteracted}
                                setSearchResults={setSearchResults}
                                currentExtent={currentExtent}
                                locations={searchResults}
                                page={page}
                                setPage={setPage}
                                searchTerms={searchTerms}
                                isMoving={isMoving}
                                isZooming={isZooming}
                                setIsMoving={setIsMoving}
                                selectedResult={selectedResult}
                                setSelectedResult={setSelectedResult}
                                isFetchingSearchResults={isFetchingSearchResults}
                                showFilters={showFilters}
                                hasWixParent={hasWixParent}
                                searchCenter={searchCenter}
                                mapRef={mapRef}
                            />
                            <PrintLocationList
                                mapInteracted={mapInteracted}
                                setSearchResults={setSearchResults}
                                currentExtent={currentExtent}
                                locations={searchResults}
                                page={page}
                                setPage={setPage}
                                searchTerms={searchTerms}
                                isMoving={isMoving}
                                isZooming={isZooming}
                                setIsMoving={setIsMoving}
                                selectedResult={selectedResult}
                                setSelectedResult={setSelectedResult}
                                isFetchingSearchResults={isFetchingSearchResults}
                                showFilters={showFilters}
                                hasWixParent={hasWixParent}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Search;

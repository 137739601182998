// @flow
import React from "react";
import StyledRadio from "../../styledRadio";
import { useTranslation } from "react-i18next";

type Props = { setAvailability: Function, availability: string };

const Availability = (props: Props) => {
    const { setAvailability, availability } = props;
    const { t } = useTranslation();

    return (
        <div>
            <StyledRadio
                label={t("Any Availability")}
                name="availability"
                id="availability-A"
                onChange={() => setAvailability("any")}
                checked={availability === "any"}
                value={availability}
            />
            <StyledRadio
                label={t("Regularly Stocked")}
                name="availability"
                id="availability-B"
                onChange={() => setAvailability("regularly-stocked")}
                checked={availability === "regularly-stocked"}
                value={availability}
            />
            <StyledRadio
                label={t("Lead Time Required")}
                name="availability"
                id="availability-C"
                onChange={() => setAvailability("lead-time-required")}
                checked={availability === "lead-time-required"}
                value={availability}
            />
        </div>
    );
};

export default Availability;

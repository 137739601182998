import React, { useState, useEffect, useRef } from "react";
import ReactTooltip from "react-tooltip";

import "./App.css";
import { buildHeaders, getUrlParams, getSearchResults } from "./lib/helpers";
import config from "./config";
import Welcome from "./components/welcome";
import Header from "./components/header";
import PrintHeader from "./components/print-header";
import Footer from "./components/footer";
import Search from "./components/search";
const headers = buildHeaders();

function App(props) {
    const [sections, setSections] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [isFetchingSearchResults, setIsFetchingSearchResults] = useState(false);
    const [currentExtent, setCurrentExtent] = useState();
    const [page, setPage] = useState(0);
    const [showFilters, setShowFilters] = useState(false);
    const [visitedWelcome, setVisitedWelcome] = useState(false);
    const [searchCenter, setSearchCenter] = useState(null);
    const [speciesList, setSpeciesList] = useState([]);

    const searchUUIDRef = useRef();

    const getCSSVariable = (key) =>
        window.getComputedStyle(document.documentElement).getPropertyValue(key);

    const blueTheme = {
        "--color-primary": getCSSVariable("--color-blue"),
        "--color-primary-hover": getCSSVariable("--color-blue-hover"),
        "--color-primary-selected-hover": getCSSVariable("--color-blue-selected-hover"),
        "--color-primary-background": getCSSVariable("--color-blue-background"),
    };

    const greenTheme = {
        "--color-primary": getCSSVariable("--color-green"),
        "--color-primary-hover": getCSSVariable("--color-green-hover"),
        "--color-primary-selected-hover": getCSSVariable("--color-green-selected-hover"),
        "--color-primary-background": getCSSVariable("--color-green-background"),
    };

    const receiveMessage = (event) => {
        if (!event.data.source && typeof event.data !== "string") {
            // console.log("received postMessage", event.data);
        }
        if (event.data && event.data.search && typeof event.data.search === "string") {
            // console.log("loading Search Terms after receiving Message", event.data.search);
            searchUUIDRef.current = event.data.search;
            loadSearchTerms(event.data.search);
            setCurrentSearchUUID(event.data.search);
        }
    };

    const params = getUrlParams(window.location.search);
    const affiliate = params && params.affiliate ? params.affiliate : "buyer-portal";
    const hasWixParent = params && params.wix ? params.wix : false;

    let hasParent = false;
    if (affiliate !== "buyer-portal") {
        hasParent = true;
    }
    // console.log("affiliate", affiliate);
    // console.log("hasWixParent", hasWixParent);
    // console.log("hasParent", hasParent);

    // code to spoof wix class
    document.body.classList.add("wix");

    useEffect(() => {
        const greenThemeAffiliates = ["buyer-portal", "fsc-us", "buildwithfsc.com"];
        const theme = greenThemeAffiliates.includes(affiliate) ? greenTheme : blueTheme;
        Object.keys(theme).forEach((key) => {
            const value = theme[key];
            document.documentElement.style.setProperty(key, value);
        });
    }, [affiliate, blueTheme, greenTheme]);

    useEffect(() => {
        if (hasParent) {
            window.addEventListener("message", receiveMessage, false);
            return () => window.removeEventListener("message");
        }
    }, [hasParent]);

    // console.log("searchResults", searchResults);

    const loadSearchTerms = async (searchUUID) => {
        const fetchSearchRequest = async (): void => {
            const result = await fetch(`${config.apiRoot}/search-request/${searchUUID}/`, {
                method: "GET",
                headers: headers,
            });
            const json = await result.json();
            return json;
        };
        const jsonSearchRequest = await fetchSearchRequest();
        const appSearchTerms = {
            selectedProducts: jsonSearchRequest.products,
            buyerType: jsonSearchRequest.buyerType,
            selectedSpecies: jsonSearchRequest.species,
            availability: jsonSearchRequest.availability,
            postalCode: jsonSearchRequest.postalCode,
            postalCodeCountry: jsonSearchRequest.country,
            fscMembership: jsonSearchRequest.fscMembership,
            supplierTypes: jsonSearchRequest.supplierTypes,
            fromSearchUID: true,
        };
        setSearchTerms(appSearchTerms);

        const officialSearchTerms = {
            products: jsonSearchRequest.products,
            buyerType: jsonSearchRequest.buyerType,
            species: jsonSearchRequest.species,
            availability: jsonSearchRequest.availability,
            postalCode: jsonSearchRequest.postalCode,
            country: jsonSearchRequest.country,
            fscMembership: jsonSearchRequest.fscMembership,
            supplierTypes: jsonSearchRequest.supplierTypes,
            fromSearchUID: true,
        };

        // console.log("app.js: loadSearchTerms - immediately before calling getSearchResults");
        // console.log("   officialSearchTerms:", officialSearchTerms);
        // console.log("   affiliate:", affiliate);

        const searchJSON = await getSearchResults(officialSearchTerms, affiliate);
        setSearchResults(searchJSON.results);
        setSearchCenter(searchJSON.center);
    };

    // set parentURL
    let parentURL = null;
    if (params && params.parent) {
        parentURL = params.parent.slice(0, params.parent.indexOf("?"));
    } else {
        parentURL = `${config.frontEndRoot}`;
    }

    // console.log("params", params);
    // set searchUUID
    let searchUUID = null;
    if (
        params &&
        params.parent &&
        params.parent.indexOf("?") > -1 &&
        params.parent.slice(params.parent.indexOf("?"))
    ) {
        // console.log(
        //     params,
        //     params.parent,
        //     params.parent.indexOf("?"),
        //     params.parent.slice(params.parent.indexOf("?")),
        // );
        const parent_params = getUrlParams(params.parent.slice(params.parent.indexOf("?")));
        searchUUID = parent_params.search;
    } else if (params && params.search) {
        searchUUID = params.search;
    } else if (searchUUIDRef.current) {
        searchUUID = searchUUIDRef.current;
    }

    const [currentSearchUUID, setCurrentSearchUUID] = useState(searchUUID);

    const [searchTerms, setSearchTerms] = useState({
        buyerType: "contractor",
        postalCode: "",
        postalCodeCountry: "US",
        availability: "any",
        selectedSpecies: [],
        selectedProducts: [],
        fscMembership: "all",
        supplierTypes: [],
        fromSearchUID: searchUUID ? true : false,
    });

    const [showWelcomeScreen, setShowWelcomeScreen] = useState(searchUUID === null ? true : false);

    // console.log("App.js: showWelcomeScreen:", showWelcomeScreen);
    // console.log("App.js: searchUUID:", searchUUID);
    // console.log("App.js: searchTerms.fromSearchUID:", searchTerms.fromSearchUID);

    useEffect(() => {
        // console.log("App.js useEffect for loadSearchTerms - checking");
        // console.log("    searchUUID:", searchUUID);
        // console.log("    showWelcomeScreen:", showWelcomeScreen);
        // console.log("    visitedWelcome:", visitedWelcome);
        // console.log("   searchTerms:", searchTerms);

        if (searchUUID && !visitedWelcome && searchTerms.fromSearchUID) {
            // console.log(
            //     "app.js useEffect for loadSearchTerms - acting upon searchUID in query params, loading searchTerms",
            // );
            loadSearchTerms(searchUUID);
        } else {
            // console.log("app.js searchUUID useEffect - skipping");
        }
    }, [searchUUID, searchTerms.fromSearchUID, visitedWelcome]);

    useEffect(() => {
        if (searchUUID) {
            setCurrentSearchUUID(searchUUID);
            setShowWelcomeScreen(false);
        }
    }, [searchUUID]);
    // useEffect(() => {
    //     const params = getUrlParams(window.location.search);
    //     setAffiliate(params && params.affiliate ? params.affiliate : "buyer-portal");
    //     setHasWixParent(params && params.wix ? params.wix : false);

    //     let searchUUID = null;
    //     if (params && params.parent) {
    //         setParentURL(params.parent.slice(0, params.parent.indexOf("?")));
    //     } else {
    //         setParentURL(`${config.frontEndRoot}`);
    //     }
    //     if (params && params.parent && params.parent.slice(params.parent.indexOf("?"))) {
    //         const parent_params = getUrlParams(params.parent.slice(params.parent.indexOf("?")));
    //         searchUUID = parent_params.search;
    //     } else if (params && params.search) {
    //         searchUUID = params.search;
    //     }

    //     if (searchUUID) {
    //         console.log("found searchUUID in query_params");
    //         loadSearchTerms(searchUUID);
    //         // setCurrentSearchUUID(searchUUID);
    //     }
    // }, []);

    useEffect(() => {
        const getSpeciesList = async (): void => {
            const result = await fetch(`${config.apiRoot}/species/`, {
                method: "GET",
                headers: buildHeaders(),
            });
            const json = await result.json();
            setSpeciesList(json);

            // to select all species by default uncomment the lines below.
            // setSearchTerms(() => ({
            //     ...searchTerms,
            //     selectedSpecies: json.map((species) => {
            //         return species.id;
            //     }),
            // }));
        };
        getSpeciesList();
    }, []);

    useEffect(() => {
        const getSections = async (): void => {
            const result = await fetch(`${config.apiRoot}/section/?available=True`, {
                method: "GET",
                headers: headers,
            });
            const json = await result.json();
            setSections(json);
        };
        getSections();
    }, []);

    useEffect(() => {
        if (!showWelcomeScreen) {
            window.parent.postMessage({ command: "scrollToTopAfterWelcome" }, "*");
            window.scrollTo(0, 0);
        }
    }, [showWelcomeScreen]);

    useEffect(() => {
        if (hasWixParent) {
            document.body.classList.add("wix");
        } else {
            document.body.classList.remove("wix");
        }
        return () => document.body.classList.remove("wix");
    }, [hasWixParent]);

    return (
        <div
            className="App"
            onClick={() => {
                ReactTooltip.hide();
            }}
        >
            {!hasWixParent && hasParent && <base target="_parent" />}
            <Header
                showWelcomeScreen={showWelcomeScreen}
                searchResults={searchResults}
                affiliate={affiliate}
                searchUUID={currentSearchUUID}
                hasWixParent={hasWixParent}
                parentURL={parentURL}
            />
            <PrintHeader searchTerms={searchTerms} />

            {showWelcomeScreen && (
                <Welcome
                    setVisitedWelcome={setVisitedWelcome}
                    sections={sections}
                    searchTerms={searchTerms}
                    setSearchTerms={setSearchTerms}
                    setIsFetchingSearchResults={setIsFetchingSearchResults}
                    isFetchingSearchResults={isFetchingSearchResults}
                    setShowWelcomeScreen={setShowWelcomeScreen}
                    setSearchResults={setSearchResults}
                />
            )}

            {!showWelcomeScreen && (
                <Search
                    setCurrentSearchUUID={setCurrentSearchUUID}
                    visitedWelcome={visitedWelcome}
                    affiliate={affiliate}
                    setCurrentExtent={setCurrentExtent}
                    searchResults={searchResults}
                    setPage={setPage}
                    speciesList={speciesList}
                    searchTerms={searchTerms}
                    setSearchTerms={setSearchTerms}
                    isFetchingSearchResults={isFetchingSearchResults}
                    setIsFetchingSearchResults={setIsFetchingSearchResults}
                    sections={sections}
                    setSearchResults={setSearchResults}
                    currentExtent={currentExtent}
                    page={page}
                    hasWixParent={hasWixParent}
                    showFilters={showFilters}
                    setShowFilters={setShowFilters}
                    searchCenter={searchCenter}
                    setSearchCenter={setSearchCenter}
                />
            )}
            <Footer hasWixParent={hasWixParent} />
            <div data-iframe-height="true"></div>
        </div>
    );
}

export default App;

// @flow
import React from "react";

function Loader() {
    return (
        <div
            style={{
                display: "flex",
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            Loading...
        </div>
    );
}

export default Loader;

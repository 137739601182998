// @flow
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import config from "../../config";
import { buildHeaders, updateQueryStringParam } from "../../lib/helpers";
import Tooltip from "../tooltip/index";
import Availability from "./availability";
import BuyerType from "./buyer-type/index";
import styles from "./index.module.scss";
import Products from "./products";
import Proximity from "./proximity";
import Species from "./species";
import SupplierType from "./supplier-type";
import { getProductNameById, getProductList } from "../../lib/helpers";

import type { SectionsType, LocationsType, SpeciesListType, ProductsType } from "../../types";

type Props = {
    affiliate: string,
    sections: SectionsType,
    setIsFetchingSearchResults: (boolean) => void,
    setSearchResults: (LocationsType) => void,
    searchTerms: Object,
    setSearchTerms: (Object) => void,
    searchResults: LocationsType,
    speciesList: SpeciesListType,
    // searchUUID: string,
    showFilters: boolean,
    setCurrentSearchUUID: (any) => void,
};

const SearchFilter = (props: Props) => {
    const {
        affiliate,
        sections,
        setIsFetchingSearchResults,
        setSearchResults,
        searchTerms,
        setSearchTerms,
        speciesList,
        showFilters,
        setCurrentSearchUUID,
        setSearchCenter,
    } = props;
    const { t } = useTranslation();

    const [toolTipShowing, setToolTipShowing] = useState(false);
    useEffect(() => {
        // console.log("search-filter: should we scroll to top?");

        if (!showFilters) {
            // console.log("search-filter: telling parent/local to scroll to top");
            window.parent.postMessage({ command: "scrollToTop" }, "*");
            window.scrollTo(0, 0);
        }
    }, [searchTerms, showFilters]);

    const search = useCallback(async (officialSearchTerms) => {
        const searchResult = await fetch(`${config.apiRoot}/search`, {
            method: "POST",
            headers: buildHeaders(),
            body: JSON.stringify(officialSearchTerms),
        });
        const searchJSON = await searchResult.json();
        // console.log(
        //     "search - officialSearchTerms.fromSearchUID: ",
        //     officialSearchTerms.fromSearchUID,
        // );
        if (officialSearchTerms.fromSearchUID !== true) {
            // console.log(
            //     " posting to search-request based on fromSearchUID being falsey: ",
            //     officialSearchTerms.fromSearchUID,
            // );
            const resultSearchRequest = await fetch(`${config.apiRoot}/search-request`, {
                method: "POST",
                headers: buildHeaders(),
                body: JSON.stringify({
                    ...officialSearchTerms,
                    shortName: affiliate,
                    locations: searchJSON.results.map((location) => location.id),
                }),
            });
            if (resultSearchRequest.status === 200) {
                const jsonSearchRequest = await resultSearchRequest.json();
                setCurrentSearchUUID(jsonSearchRequest.uuid);
                if (affiliate !== "buyer-portal") {
                    // console.log(
                    //     "search-filter: affiliate was not buyer-portal, thus we expect iframe",
                    // );
                    // console.log("search-filter: about to postMessage to change search");
                    window.parent.postMessage({ search: jsonSearchRequest.uuid }, "*");
                } else {
                    // console.log("updating search address bar for non-iframe implementation");
                    updateQueryStringParam("search", jsonSearchRequest.uuid);
                }
            } else {
                console.log("Search Request did not save.");
            }
        } else {
            console.log("did not post to search-request because fromSearchUID was true");
        }
        setSearchResults(searchJSON.results);
        setSearchCenter(searchJSON.center);
        setIsFetchingSearchResults(false);
    }, []);

    const lastTerms = useRef(null);
    useEffect(() => {
        // console.log("considering search");
        const {
            buyerType,
            selectedProducts,
            selectedSpecies,
            availability,
            postalCode,
            postalCodeCountry,
            fscMembership,
            supplierTypes,
            fromSearchUID,
        } = searchTerms;

        const officialSearchTerms = {
            products: selectedProducts,
            buyerType,
            species: selectedSpecies,
            availability,
            postalCode,
            country: postalCodeCountry,
            fscMembership,
            supplierTypes,
            fromSearchUID,
        };
        // if (lastTerms.current) {
        //     console.log(
        //         "comparator current to new",
        //         lastTerms.current.products === officialSearchTerms.products,
        //         lastTerms.current.buyerType === officialSearchTerms.buyerType,
        //         lastTerms.current.species === officialSearchTerms.species,
        //         lastTerms.current.availability === officialSearchTerms.availability,
        //         lastTerms.current.postalCode === officialSearchTerms.postalCode,
        //         lastTerms.current.country === officialSearchTerms.country,
        //         lastTerms.current.fscMembership === officialSearchTerms.fscMembership,
        //         lastTerms.current.supplierTypes === officialSearchTerms.supplierTypes,
        //     );
        // }
        if (
            lastTerms.current &&
            lastTerms.current.products === officialSearchTerms.products &&
            lastTerms.current.buyerType === officialSearchTerms.buyerType &&
            lastTerms.current.species === officialSearchTerms.species &&
            lastTerms.current.availability === officialSearchTerms.availability &&
            lastTerms.current.postalCode === officialSearchTerms.postalCode &&
            lastTerms.current.country === officialSearchTerms.country &&
            lastTerms.current.fscMembership === officialSearchTerms.fscMembership &&
            lastTerms.current.supplierTypes === officialSearchTerms.supplierTypes
        ) {
            // console.log(
            //     "search-filter.js - Doing nothing because all values in searchTerms appear to be unchanged.",
            // );
            // do nothing
        } else {
            lastTerms.current = officialSearchTerms;
            // testing that data is present, not just initial data...
            // console.log("search-filter.js useEffect - checking");
            // console.log("   buyerType:", buyerType);
            // console.log("   fromSearchUID:", fromSearchUID);
            if (buyerType !== null && !fromSearchUID) {
                // console.log("Searching...");

                setIsFetchingSearchResults(true);
                search(officialSearchTerms);
            } else {
                // console.log("Not Searching...", officialSearchTerms);
            }
        }
    }, [search, searchTerms, setIsFetchingSearchResults]);
    // }, [createSearchRequest, search, searchTerms, setIsFetchingSearchResults]);

    const productList: ProductsType = getProductList(sections);
    const printProducts = searchTerms.selectedProducts
        .map((product) => {
            return t(getProductNameById(product, productList));
        })
        .join(", ");

    const printSpecies = searchTerms.selectedSpecies
        .map((speciesId) => {
            return t(
                speciesList.find((listItem) => {
                    return listItem.id === speciesId;
                }).name,
            );
        })
        .join(", ");

    const getPrintBuyerType = () => {
        switch (searchTerms.buyerType) {
            case "wholesale":
                return t("Manufacturer") + " / " + t("Distributor") + " / " + t("Retailer");
            case "contractor":
                return t("AEC Professional");
            case "retail":
                return t("Homeowner / DIYer");
            default:
                return t("No Buyer Type Selected");
        }
    };

    const getPrintAvailability = () => {
        switch (searchTerms.availability) {
            case "any":
                return t("Any Availability");
            case "regularly-stocked":
                return t("Regularly Stocked");
            case t("leadtime-required"):
                return t("Leadtime Required");
            default:
                return t("No Availabillity Selected");
        }
    };

    const getPrintMembership = () => {
        switch (searchTerms.fscMembership) {
            case "all":
                return t("Show All FSC Certificate Holders");
            case "only-fsc-members":
                return t("Only Show FSC Certificate Holders who are also FSC Members");
            default:
                return t("No FSC Membership Selected");
        }
    };

    return (
        <>
            <div className={styles["print-filters"]}>
                <dl>
                    <dt>{t("Buyer Type")}</dt>
                    <dd>{getPrintBuyerType()}</dd>

                    <dt>{t("Products")}</dt>
                    <dd>{printProducts || t("None selected")}</dd>

                    <dt>{t("Species")}</dt>
                    <dd>{printSpecies || t("None selected")}</dd>

                    <dt>{t("Availability")}</dt>
                    <dd>{getPrintAvailability()}</dd>
                    {searchTerms.postalCode && (
                        <>
                            <dt>{t("Proximity")}</dt>
                            <dd>
                                {searchTerms.postalCode} {searchTerms.postalCodeCountry}
                            </dd>
                        </>
                    )}

                    <dt>{t("Supplier Type")}</dt>
                    <dd>
                        {searchTerms.supplierTypes
                            .map((supplierType) => {
                                return t(supplierType);
                            })
                            .join(", ")}
                    </dd>

                    <dt>{t("FSC Membership")}</dt>
                    <dd>{getPrintMembership()}</dd>
                </dl>
            </div>
            <div className={styles["filters"]}>
                <ul className={styles["categories"]}>
                    <li>
                        <h3>{t("Buyer Type")}</h3>
                        <Tooltip
                            style={{ position: "absolute", top: "21px", right: "0" }}
                            field="buyerType"
                            toolTipShowing={toolTipShowing}
                            setToolTipShowing={setToolTipShowing}
                        />
                        <BuyerType
                            setBuyerType={(value) => {
                                setSearchTerms({
                                    ...searchTerms,
                                    buyerType: value,
                                    fromSearchUID: false,
                                });
                            }}
                            buyerType={searchTerms.buyerType}
                        />
                    </li>
                    <li>
                        <h3>{t("Products")}</h3>
                        <Tooltip
                            style={{ position: "absolute", top: "21px", right: "0" }}
                            field="products"
                            toolTipShowing={toolTipShowing}
                            setToolTipShowing={setToolTipShowing}
                        />
                        <Products
                            showHeader={false}
                            showSearchIcon={false}
                            sections={sections}
                            searchTerms={searchTerms}
                            setSearchTerms={setSearchTerms}
                        />
                    </li>
                    <li>
                        <h3>{t("Availability")}</h3>
                        <Tooltip
                            style={{ position: "absolute", top: "21px", right: "0" }}
                            field="availability"
                            toolTipShowing={toolTipShowing}
                            setToolTipShowing={setToolTipShowing}
                        />
                        <Availability
                            setAvailability={(value) => {
                                setSearchTerms({
                                    ...searchTerms,
                                    availability: value,
                                    fromSearchUID: false,
                                });
                            }}
                            availability={searchTerms.availability}
                        />
                    </li>
                    <li>
                        <h3>{t("Proximity")}</h3>
                        <Tooltip
                            style={{ position: "absolute", top: "21px", right: "0" }}
                            field="proximity"
                            toolTipShowing={toolTipShowing}
                            setToolTipShowing={setToolTipShowing}
                        />
                        <Proximity searchTerms={searchTerms} setSearchTerms={setSearchTerms} />
                    </li>
                    <li>
                        <h3>{t("Species")}</h3>
                        <Tooltip
                            style={{ position: "absolute", top: "21px", right: "0" }}
                            field="species"
                            toolTipShowing={toolTipShowing}
                            setToolTipShowing={setToolTipShowing}
                        />
                        <ul className={styles["category-list"]}>
                            <Species
                                setSearchTerms={setSearchTerms}
                                speciesList={speciesList}
                                searchTerms={searchTerms}
                            />
                        </ul>
                    </li>
                    <li>
                        <h3>{t("Supplier Type")}</h3>
                        <Tooltip
                            style={{ position: "absolute", top: "21px", right: "0" }}
                            field="supplierType"
                            toolTipShowing={toolTipShowing}
                            setToolTipShowing={setToolTipShowing}
                        />
                        <SupplierType searchTerms={searchTerms} setSearchTerms={setSearchTerms} />
                    </li>
                    {/* <li>
                        <h3>{t("FSC Members")}</h3>
                        <Tooltip
                            style={{ position: "absolute", top: "21px", right: "0" }}
                            field="fscMembers"
                            toolTipShowing={toolTipShowing}
                            setToolTipShowing={setToolTipShowing}
                        />
                        <FSCMembership searchTerms={searchTerms} setSearchTerms={setSearchTerms} />
                        <img
                            alt="FSC Logo denoting membership"
                            src={FSCLogo}
                            className={styles["fsc-certified"]}
                        />
                    </li> */}
                </ul>
                <div data-iframe-height="true"></div>
            </div>
        </>
    );
};
export default SearchFilter;

// @flow
import React from "react";
import PrintLocation from "./print-location";
import type { LocationType } from "../../types";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

const PrintLocationList = (props) => {
    const {
        currentExtent,
        locations,
        searchTerms,
        selectedResult,
        setSelectedResult,
        hasWixParent,
    } = props;
    const { t } = useTranslation();

    const pointInside = (location: LocationType) => {
        if (currentExtent) {
            const { latitude, longitude } = location;
            const [nw, se] = currentExtent.toArray();
            return latitude < se[1] && latitude > nw[1] && longitude < se[0] && longitude > nw[0];
        }
        return true;
    };

    const filteredLocations = locations.filter(pointInside);
    var resultCount = 0;
    if (filteredLocations) {
        resultCount = filteredLocations.length;
    }

    return (
        <>
            {resultCount > 0 && (
                <div className={styles["results-grid"]}>
                    <ul className={styles["results-cards"]}>
                        {filteredLocations.map((location, idx) => (
                            <PrintLocation
                                key={location.id}
                                location={location}
                                searchTerms={searchTerms}
                                idx={idx + 1}
                                setSelectedResult={setSelectedResult}
                                selectedResult={selectedResult}
                                hasWixParent={hasWixParent}
                            />
                        ))}
                    </ul>
                </div>
            )}
            {/* this shows when map is moving/dragging but there are no results  */}
            {resultCount === 0 && (
                <div className={styles["no-results"]}>
                    <h1>{t("No Results Found.")}</h1>
                </div>
            )}
        </>
    );
};

export default PrintLocationList;

import React from "react";
import styles from "./index.module.scss";
import logo from "../../assets/images/FSC-logo.png";
import { useTranslation } from "react-i18next";

const PrintHeader = () => {
    const { t } = useTranslation();

    return (
        <div className={styles["print-header"]}>
            <img src={logo} alt="FSC LOGO" width="40px" />
            <h1>{t("FSC Suppliers Matching Criteria")}</h1>
        </div>
    );
};

export default PrintHeader;

// @flow
import React from "react";
import StyledCheck from "../../styledCheck";

type Props = {
    setSearchTerms: (Object) => void,
    searchTerms: Object,
};
const SupplierType = (props: Props) => {
    const { searchTerms, setSearchTerms } = props;

    const toggleSuppliers = (value) => {
        if (searchTerms.supplierTypes.includes(value)) {
            setSearchTerms({
                ...searchTerms,
                supplierTypes: searchTerms.supplierTypes.filter(
                    (supplierType) => supplierType !== value,
                ),
                fromSearchUID: false,
            });
        } else {
            setSearchTerms({
                ...searchTerms,
                supplierTypes: [...searchTerms.supplierTypes, value],
                fromSearchUID: false,
            });
        }
    };

    return (
        <div>
            {/* <StyledCheck
                id="supplier-type-unspecified"
                checked={searchTerms.supplierTypes.includes("unspecified")}
                label="Unspecified"
                onChange={(value) => toggleSuppliers("unspecified")}
            /> */}
            <StyledCheck
                id="supplier-type-retail"
                checked={searchTerms.supplierTypes.includes("retail")}
                label="Retail"
                onChange={(value) => toggleSuppliers("retail")}
            />
            <StyledCheck
                id="supplier-type-specialty-woodworking"
                checked={searchTerms.supplierTypes.includes("specialty-woodworking")}
                label="Specialty Woodworking"
                onChange={(value) => toggleSuppliers("specialty-woodworking")}
            />
            <StyledCheck
                id="supplier-type-wholesale"
                checked={searchTerms.supplierTypes.includes("wholesale")}
                label="Wholesale Distributor"
                onChange={(value) => toggleSuppliers("wholesale")}
            />
            <StyledCheck
                id="supplier-type-secondary-manufacturing"
                checked={searchTerms.supplierTypes.includes("secondary-manufacturing")}
                label="Secondary Manufacturer"
                onChange={(value) => toggleSuppliers("secondary-manufacturing")}
            />
            <StyledCheck
                id="supplier-type-primary-manufacturing"
                checked={searchTerms.supplierTypes.includes("primary-manufacturing")}
                label="Primary Manufacturer"
                onChange={(value) => toggleSuppliers("primary-manufacturing")}
            />
        </div>
    );
};

export default SupplierType;

// @flow
import React from "react";
import BuyerTypeButton from "./buyer-type-button";
import styles from "./index.module.scss";

type Props = { setSearchTerms: (Object) => void, searchTerms: Object };

const WelcomeBuyerType = (props: Props) => {
    const { searchTerms, setSearchTerms } = props;

    return (
        <div className={styles["welcome-buttons"]}>
            <BuyerTypeButton
                typeList={["Manufacturer", "Distributor", "Retailer"]}
                searchTerms={searchTerms}
                setSearchTerms={setSearchTerms}
                value={"wholesale"}
            />
            <BuyerTypeButton
                typeList={["AEC Professional", ""]}
                searchTerms={searchTerms}
                setSearchTerms={setSearchTerms}
                value={"contractor"}
            />
            <BuyerTypeButton
                typeList={["Homeowner", "DIYer", ""]}
                searchTerms={searchTerms}
                setSearchTerms={setSearchTerms}
                value={"retail"}
            />
        </div>
    );
};

export default WelcomeBuyerType;

// @flow
import React from "react";
import StyledCheck from "../../styledCheck";
import styles from "./index.module.scss";
import type { SpeciesListType } from "../../../types";

type Props = {
    searchTerms: Object,
    setSearchTerms: (Object) => void,
    speciesList: SpeciesListType,
};

const Species = (props: Props) => {
    const { searchTerms, setSearchTerms, speciesList } = props;

    const toggleSpecies = (value) => {
        if (searchTerms.selectedSpecies.includes(value)) {
            setSearchTerms({
                ...searchTerms,
                selectedSpecies: searchTerms.selectedSpecies.filter((species) => species !== value),
                fromSearchUID: false,
            });
        } else {
            setSearchTerms({
                ...searchTerms,
                selectedSpecies: [...searchTerms.selectedSpecies, value],
                fromSearchUID: false,
            });
        }
    };

    const hardwoods = speciesList.filter((species) => species.classification === "hardwood");

    const softwoods = speciesList.filter((species) => species.classification === "softwood");

    return (
        <div className={styles["species-lists"]}>
            <ul className={styles["category-list"]}>
                <li className={styles["select-all"]}>
                    <StyledCheck
                        id="select-all-hardwood"
                        checked={hardwoods.every((hardwood) =>
                            searchTerms.selectedSpecies.includes(hardwood.id),
                        )}
                        label="Any Hardwood"
                        onChange={(e) => {
                            if (!e.target.checked) {
                                setSearchTerms({
                                    ...searchTerms,
                                    selectedSpecies: searchTerms.selectedSpecies.filter(
                                        (species) =>
                                            !hardwoods
                                                .map((hardwood) => hardwood.id)
                                                .includes(species),
                                    ),
                                    fromSearchUID: false,
                                });
                            } else {
                                setSearchTerms({
                                    ...searchTerms,
                                    selectedSpecies: [
                                        ...new Set([
                                            ...searchTerms.selectedSpecies,
                                            ...hardwoods.map((hardwood) => hardwood.id),
                                        ]),
                                    ],
                                    fromSearchUID: false,
                                });
                            }
                        }}
                    />
                </li>

                {hardwoods.map((species) => (
                    <li key={species.id}>
                        <StyledCheck
                            id={species.id}
                            checked={searchTerms.selectedSpecies.includes(species.id)}
                            label={species.name}
                            onChange={() => toggleSpecies(species.id)}
                        />
                    </li>
                ))}
            </ul>
            <ul className={styles["category-list"]}>
                <li className={styles["select-all"]}>
                    <StyledCheck
                        id="select-all-softwood"
                        checked={softwoods.every((softwood) =>
                            searchTerms.selectedSpecies.includes(softwood.id),
                        )}
                        label="Any Softwood"
                        onChange={(e) => {
                            if (!e.target.checked) {
                                setSearchTerms({
                                    ...searchTerms,
                                    selectedSpecies: searchTerms.selectedSpecies.filter(
                                        (species) =>
                                            !softwoods
                                                .map((softwood) => softwood.id)
                                                .includes(species),
                                    ),
                                    fromSearchUID: false,
                                });
                            } else {
                                setSearchTerms({
                                    ...searchTerms,
                                    selectedSpecies: [
                                        ...new Set([
                                            ...searchTerms.selectedSpecies,
                                            ...softwoods.map((softwood) => softwood.id),
                                        ]),
                                    ],
                                    fromSearchUID: false,
                                });
                            }
                        }}
                    />
                </li>
                {softwoods.map((species) => (
                    <li key={species.id}>
                        <StyledCheck
                            id={species.id}
                            checked={searchTerms.selectedSpecies.includes(species.id)}
                            label={species.name}
                            onChange={() => toggleSpecies(species.id)}
                            value
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Species;

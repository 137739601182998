// @flow
import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";

type Props = {
    searchTerms: Object,
    setSearchTerms: (Object) => void,
};

const Proximity = (props: Props) => {
    const { searchTerms, setSearchTerms } = props;
    const { postalCodeCountry, postalCode } = searchTerms;
    const [tempPostalCode, setTempPostalCode] = useState(postalCode);

    const getSelectedText = () => {
        var text = "";
        if (typeof window.getSelection !== "undefined") {
            text = window.getSelection().toString();
        } else if (
            typeof document.selection !== "undefined" &&
            document.selection.type === "Text"
        ) {
            text = document.selection.createRange().text;
        }
        return text;
    };

    const onKeyDown = (e) => {
        if (e.key === "Backspace" || e.key === "Delete") {
            if (tempPostalCode.length >= 5 && postalCodeCountry !== "CA") {
                e.preventDefault();
                setTempPostalCode("");
            }
            if (tempPostalCode.length >= 6 && postalCodeCountry === "CA") {
                e.preventDefault();
                setTempPostalCode("");
            }
        }
    };

    const onKeyPress = (e) => {
        const keyCode = e.keyCode || e.which;
        const keyValue = String.fromCharCode(keyCode);
        const selectedText = getSelectedText();

        if (postalCodeCountry === "US" || postalCodeCountry === "MX") {
            if (!/\d/.test(keyValue)) {
                e.preventDefault();
            } else if (selectedText.length === 5) {
                setTempPostalCode(keyValue);
            } else if (tempPostalCode.length >= 5) {
                e.preventDefault();
            }
        } else {
            const postalCodeRegex = /[ABCEGHJ-NPRSTVWXYZ]|\d/i;
            if (!postalCodeRegex.test(keyValue)) {
                e.preventDefault();
            } else if (selectedText.replace(" ").length === 6) {
                setTempPostalCode(keyValue);
            } else if (tempPostalCode.replace(" ").length >= 6) {
                e.preventDefault();
            }
        }
    };
    const isValid =
        postalCodeCountry === "US" || postalCodeCountry === "MX"
            ? /^\d{5}$/.test(tempPostalCode)
            : /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(
                  tempPostalCode,
              );
    useEffect(() => {
        if (isValid) {
            setSearchTerms({
                ...searchTerms,
                postalCode: tempPostalCode,
                fromSearchUID: false,
            });
        } else if (searchTerms.postalCode) {
            setSearchTerms({
                ...searchTerms,
                postalCode: "",
                fromSearchUID: false,
            });
        }
    }, [isValid, tempPostalCode, setSearchTerms]);

    useEffect(() => {
        if (postalCode !== "") {
            setTempPostalCode(postalCode);
        }
    }, [postalCode]);

    // FIXME: something seems to be amiss here -- if i add searchTerms to useEffect -- recurrsion.

    // const getInputClassName = () => {
    //     if (tempPostalCode.length) {
    //         return isValid ? styles.valid : styles.invalid;
    //     } else {
    //         return "";
    //     }
    // };

    const getPlaceHolder = () => {
        switch (postalCodeCountry) {
            case "US":
                return "Zip Code";
            case "MX":
                return "Código Postal";
            case "CA":
                return "Postal Code";
            default:
                return "Postal Code";
        }
    };
    return (
        <div className={`${styles["proximity-filter"]} ${isValid ? styles.success : ""}`}>
            <div>{isValid}</div>
            <input
                onKeyDown={onKeyDown}
                placeholder={getPlaceHolder()}
                onKeyPress={onKeyPress}
                value={tempPostalCode}
                onChange={(e) => {
                    setTempPostalCode(e.target.value);
                }}
            />
            <div className={styles["country-toggle"]}>
                <span
                    className={`${styles.usa} ${postalCodeCountry === "US" ? styles.active : ""}`}
                    onClick={() => {
                        setSearchTerms({
                            ...searchTerms,
                            postalCodeCountry: "US",
                            fromSearchUID: false,
                        });
                        setTempPostalCode("");
                    }}
                >
                    <span></span>
                </span>
                <span
                    className={`${styles.canada} ${
                        postalCodeCountry === "CA" ? styles.active : ""
                    }`}
                    onClick={() => {
                        setSearchTerms({
                            ...searchTerms,
                            postalCodeCountry: "CA",
                            fromSearchUID: false,
                        });
                        setTempPostalCode("");
                    }}
                >
                    <span></span>
                </span>
                <span
                    className={`${styles.mexico} ${
                        postalCodeCountry === "MX" ? styles.active : ""
                    }`}
                    onClick={() => {
                        setSearchTerms({
                            ...searchTerms,
                            postalCodeCountry: "MX",
                            fromSearchUID: false,
                        });
                        setTempPostalCode("");
                    }}
                >
                    <span></span>
                </span>
            </div>
        </div>
    );
};

export default Proximity;

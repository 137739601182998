// @flow
import React, { useEffect } from "react";
import Location from "./location/index";
import type { LocationsType, LocationType } from "../../types";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import LoadingOverlay from "react-loading-overlay";
import { LngLat, LngLatBounds } from "mapbox-gl";

type Props = {
    searchTerms: Object,
    locations: LocationsType,
    page: number,
    isMoving: boolean,
    isFetchingSearchResults: boolean,
    showFilters: boolean,
    hasWixParent: boolean,
    isZooming: boolean,
    mapRef: Object,
    searchCenter: Object,
};

const LocationList = (props: Props) => {
    const {
        currentExtent,
        locations,
        page,
        setPage,
        searchTerms,
        isMoving,
        isFetchingSearchResults,
        selectedResult,
        setSelectedResult,
        showFilters,
        hasWixParent,
        isZooming,
        mapRef,
        searchCenter,
    } = props;
    const { t } = useTranslation();

    const pointInside = (location: LocationType) => {
        if (currentExtent) {
            const { latitude, longitude } = location;
            const [nw, se] = currentExtent.toArray();
            return latitude < se[1] && latitude > nw[1] && longitude < se[0] && longitude > nw[0];
        }
        return true;
    };

    const filteredLocations = locations.filter(pointInside);

    const resultCount = filteredLocations.length;
    const maxPage = parseInt(Math.ceil(resultCount / 8), 10) - 1;

    const currentResultsNumber =
        filteredLocations.length < (page + 1) * 8 ? filteredLocations.length : (page + 1) * 8;

    let resultsOnPage = 8;
    if (page === maxPage) {
        resultsOnPage = resultCount % 8;
    }

    let startNum = currentResultsNumber - 7 > 0 ? currentResultsNumber - 7 : 1;
    if (page === maxPage) {
        if (resultsOnPage === 0) {
            startNum = resultCount - 7;
        } else {
            startNum = resultCount - resultsOnPage + 1;
        }
    }

    useEffect(() => {
        if (page > maxPage && maxPage >= 0) {
            setPage(maxPage);
        }
    }, [page, maxPage, setPage]);

    const noResultsText = () => {
        let text: string = "";
        let zoomText: boolean = false;
        if (!isFetchingSearchResults) {
            if (!isMoving && !isZooming) {
                if (filteredLocations && filteredLocations.length === 0 && locations.length === 0) {
                    /* this shows when there are no results at all */
                    text = t("No Results Found.");
                } else if (
                    filteredLocations &&
                    filteredLocations.length === 0 &&
                    locations.length > 0
                ) {
                    /* this shows when there are no results in view area. */
                    text =
                        t("Show all") +
                        " " +
                        locations.length.toString() +
                        " " +
                        t("results") +
                        ".";
                    zoomText = true;
                }
            } else {
                /* isMoving or isZooming */
                text = t("Loading Results...");
            }
        }
        return { text: text, zoomText: zoomText };
    };
    const showAllResults = () => {
        if (mapRef && mapRef.current) {
            const bounds = new LngLatBounds();
            const results = locations.filter((result) => result.longitude && result.latitude);

            // compromise line allowing members to be used only if they are inside window
            results.sort((a, b) => a.distance - b.distance);

            results.forEach((result) => {
                bounds.extend(new LngLat(result.longitude, result.latitude));
            });
            const ne = bounds.getNorthEast();
            const sw = bounds.getSouthWest();
            if (ne !== undefined && sw !== undefined) {
                let east = ne.lng;
                let west = sw.lng;
                let north = ne.lat;
                let south = sw.lat;
                if (searchCenter) {
                    // Calculate max Y difference
                    const northDiff = Math.abs(searchCenter.latitude - north);
                    const southDiff = Math.abs(searchCenter.latitude - south);
                    const latDiff = northDiff > southDiff ? northDiff : southDiff;

                    // Calculate max X difference
                    const westDiff = Math.abs(searchCenter.longitude - west);
                    const eastDiff = Math.abs(searchCenter.longitude - east);
                    const lonDiff = westDiff > eastDiff ? westDiff : eastDiff;

                    east = searchCenter.longitude + lonDiff;
                    west = searchCenter.longitude - lonDiff;
                    north = searchCenter.latitude + latDiff;
                    south = searchCenter.latitude - latDiff;
                }
                bounds.setNorthEast(new LngLat(east, north));
                bounds.setSouthWest(new LngLat(west, south));
            }

            mapRef.current.state.map.fitBounds(bounds, { padding: 30 });
        }
    };

    return (
        <LoadingOverlay
            active={isFetchingSearchResults && !showFilters}
            spinner={true}
            fadeSpeed={250}
            text={t("Loading")}
        >
            {filteredLocations && filteredLocations.length > 0 && (
                <div className={styles["results-grid"]}>
                    <div className={styles.pagination}>
                        <p>
                            {t("Showing")} {startNum} - {currentResultsNumber} {t("of")}{" "}
                            {filteredLocations.length} {t("results")}
                            {filteredLocations.length !== locations.length && (
                                <>
                                    &nbsp;&nbsp;&nbsp;
                                    <button className="text-link" onClick={showAllResults}>
                                        <span className={styles.allResultsLongText}>
                                            {t("Show all")} {locations.length} {t("results")}.
                                        </span>
                                        <span className={styles.allResultsShortText}>
                                            {t("All")} {locations.length} {t("results")}.
                                        </span>
                                    </button>
                                    {/* <span
                                    className={` ${styles["map-move-message"]} ${
                                        isMoving ? styles.show : ""
                                    }`}
                                >
                                    {t("Updating")} ...
                                </span> */}
                                </>
                            )}
                        </p>
                        <div className={styles["page-buttons"]}>
                            <button
                                onClick={() => {
                                    setSelectedResult(null);
                                    setPage(page - 1);
                                }}
                                className={`${styles.prev} ${page === 0 ? styles.disabled : ""}`}
                            ></button>
                            <button
                                onClick={() => {
                                    setSelectedResult(null);
                                    setPage(page + 1);
                                }}
                                className={`${styles.next} ${
                                    currentResultsNumber < filteredLocations.length
                                        ? ""
                                        : styles.disabled
                                }`}
                            ></button>
                        </div>
                    </div>
                    <ul className={styles["results-cards"]}>
                        {filteredLocations
                            .map((location, idx) => (
                                <Location
                                    key={location.id}
                                    location={location}
                                    searchTerms={searchTerms}
                                    idx={idx + 1}
                                    setSelectedResult={setSelectedResult}
                                    selectedResult={selectedResult}
                                    hasWixParent={hasWixParent}
                                />
                            ))
                            .slice(page * 8, page * 8 + 8)}
                    </ul>
                </div>
            )}
            {/* this shows when map is moving/dragging but there are no results  */}
            {noResultsText().text && (
                <div className={styles["no-results"]}>
                    {noResultsText().zoomText && (
                        <h1>
                            <button className="large-text-link" onClick={showAllResults}>
                                {noResultsText().text}
                            </button>
                        </h1>
                    )}
                    {!noResultsText().zoomText && <h1>{noResultsText().text}</h1>}
                </div>
            )}
        </LoadingOverlay>
    );
};

export default LocationList;

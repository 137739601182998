import React from "react";
import styles from "./index.module.scss";

const StyledRadio = (props) => {
    const { id, label, name, onChange, value, checked } = props;
    return (
        <div className={styles["styled-radio"]}>
            <input
                id={id}
                type="radio"
                name={name}
                onChange={onChange}
                value={value}
                checked={checked}
            />
            <label htmlFor={id}>{label}</label>
        </div>
    );
};

export default StyledRadio;

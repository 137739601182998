import React from "react";
import ReactTooltip from "react-tooltip";
import styles from "./index.module.scss";
import { useWindowDimensions } from "../../lib/helpers";
import { useTranslation } from "react-i18next";

const Tooltip = (props) => {
    const { style, field = {} } = props;
    const { height, width } = useWindowDimensions();

    const { t } = useTranslation();

    const toolTipText = {
        buyerType: (
            <ul className={styles.listTypeToolTip}>
                <li className={styles.listTypeToolTip}>
                    <div className={styles["list-types-item-header"]}>
                        {t("Manufacturers")} / {t("Distributors")} / {t("Retailers")}
                    </div>
                    <ul>
                        <li>{t("All types of secondary manufacturers")}</li>
                        <li>{t("Wholesale distributors")}</li>
                        <li>{t("Retailers")}</li>
                    </ul>
                </li>

                <li className={styles.listTypeToolTip}>
                    <div className={styles["list-type-item-header"]}>{t("AEC Professionals")}</div>
                    <ul>
                        <li>{t("Flooring or building contractors")}</li>
                        <li>{t("Cabinet shops")}</li>
                        <li>{t("Architectural millworkers")}</li>
                        <li>{t("Property owners/developers")}</li>
                        <li>{t("Architects and designers")}</li>
                    </ul>
                </li>

                <li className={styles.listTypeToolTip}>
                    <div className={styles["list-type-item-header"]}>
                        {t("Homeowners / DIYers")}
                    </div>
                    <ul>
                        <li>{t("Homeowners")}</li>
                        <li>{t("Consumers")}</li>
                    </ul>
                </li>
            </ul>
        ),
        supplierType: (
            <ul className={styles.listTypeToolTip}>
                <li className={styles.listTypeToolTip}>
                    <div className={styles["list-type-item-header"]}>{t("Unspecified")}</div>
                    <ul>
                        <li>
                            {t(
                                "Not getting many search results? Try a search with no supplier type specified.",
                            )}
                        </li>
                    </ul>
                </li>

                <li className={styles.listTypeToolTip}>
                    <div className={styles["list-type-item-header"]}>{t("Retail")}</div>
                    <ul>
                        <li>{t("Flooring, Furniture or Mass/DIY retailer")}</li>
                    </ul>
                </li>

                <li className={styles.listTypeToolTip}>
                    <div className={styles["list-type-item-header"]}>
                        {t("Specialty Woodworking")}
                    </div>
                    <ul>
                        <li>{t("Cabinet shop")}</li>
                        <li>{t("Architectural millworker")}</li>
                        <li>{t("Furniture maker")}</li>
                    </ul>
                </li>

                <li className={styles.listTypeToolTip}>
                    <div className={styles["list-type-item-header"]}>
                        {t("Wholesale Distribution")}
                    </div>
                    <ul>
                        <li>{t("Flooring, Veneer or Hardwood distributor")}</li>
                        <li>{t("Concentration Yard, Reload, Lumber Yard")}</li>
                    </ul>
                </li>

                <li className={styles.listTypeToolTip}>
                    <div className={styles["list-type-item-header"]}>
                        {t("Secondary Manufacturing")}
                    </div>
                    <ul>
                        <li>{t("Flooring mill")}</li>
                        <li>
                            {t(
                                "Furniture, Moulding, Cabinet or Engineered wood products manufacturer",
                            )}
                        </li>
                    </ul>
                </li>

                <li className={styles.listTypeToolTip}>
                    <div className={styles["list-type-item-header"]}>
                        {t("Primary Manufacturing")}
                    </div>
                    <ul>
                        <li>{t("Sawmill")}</li>
                        <li>
                            {t(
                                "Chip/fiber, Veneer, hardwood, structural plywood, OSB or MDF/particle board mill",
                            )}
                        </li>
                    </ul>
                </li>
            </ul>
        ),
        products: (
            <div>
                {t(
                    "Not sure which categories will include your product? Email admin@buildwithfsc.com for help.",
                )}
            </div>
        ),
        species: (
            <div>
                {t(
                    "Choose one or more species to ensure your results include only suppliers who have indicated that they carry those species.",
                )}
            </div>
        ),
        availability: (
            <div>
                {t("Suppliers may require lead time. Select the option that best fits your needs.")}
            </div>
        ),
        proximity: (
            <div>
                {t(
                    "Enter a zip or postal code from US, Canada or Mexico to view suppliers in order of proximity to your project location.",
                )}
            </div>
        ),
        fscMembers: (
            <div>
                {t(
                    "Not all certificate holders are FSC members. Select the second option to include only FSC members in search results.",
                )}
            </div>
        ),
    };
    const overridePosition = ({ left, top }, currentEvent, currentTarget, node) => {
        // console.log(top, left);
        let newTop = top;
        if (top < 20) {
            newTop = 20;
        }

        let newLeft = left;
        if (width < 364) {
            newLeft = 60;
        }
        return { top: newTop, left: newLeft };
    };

    return (
        <>
            <span
                className={styles["info-tooltip"]}
                data-tip={field}
                data-for="tooltip_filter"
                data-event="click focus"
                style={style}
            >
                i
            </span>
            <ReactTooltip
                id="tooltip_filter"
                effect="float"
                className={styles["tooltip-bubble"]}
                globalEventOff="click"
                data-event-off="click"
                getContent={(dataTip) => {
                    return toolTipText[dataTip];
                }}
                overridePosition={overridePosition}
                place="left"
            />
        </>
    );
};

export default Tooltip;

import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import logo from "../../assets/images/header_FSClogo_25px_wide.png";
import nnrgLogo from "../../assets/images/NNRG_Color_Small.png";

const Footer = (props) => {
    const { hasWixParent } = props;
    const { t, i18n } = useTranslation();

    return (
        <footer className={styles.footer}>
            <div>
                <h4>{t("FSC Suppliers")}</h4>
                <p>
                    {t("Want to be Listed?")}{" "}
                    {!hasWixParent && (
                        <a
                            className="text-link"
                            href="https://supplier-portal.buildwithfsc.org/register"
                        >
                            {t("Register now")}
                        </a>
                    )}
                    {hasWixParent && (
                        <button
                            className="text-link"
                            onClick={() => {
                                window.parent.postMessage(
                                    {
                                        externalSite:
                                            "https://supplier-portal.buildwithfsc.org/register",
                                    },
                                    "*",
                                );
                            }}
                        >
                            {t("Register now")}
                        </button>
                    )}
                    .<br />
                    {t("Non-US? Email")}{" "}
                    <a
                        className="text-link"
                        href="mailto:info@us.fsc.org?subject=International Supplier Account"
                    >
                        info@us.fsc.org
                    </a>{" "}
                    {t("to create an account")}.
                    <br />
                    {t("Already listed?")}{" "}
                    {!hasWixParent && (
                        <a className="text-link" href="https://supplier-portal.buildwithfsc.org/">
                            {t("Manage your profile")}
                        </a>
                    )}
                    {hasWixParent && (
                        <button
                            className="text-link"
                            onClick={() => {
                                window.parent.postMessage(
                                    {
                                        externalSite: "https://supplier-portal.buildwithfsc.org/",
                                    },
                                    "*",
                                );
                            }}
                        >
                            {t("Manage your profile")}
                        </button>
                    )}
                    .
                </p>
            </div>
            <div>
                <h4>{t("Buyers")}</h4>
                <p>
                    {t("Didn't see what you need? Let us know")}:{" "}
                    <a className="text-link" href="mailto:info@us.fsc.org">
                        info@us.fsc.org
                    </a>
                    .
                </p>
            </div>
            <div className={styles.logoRow}>
                <div className={styles.fscLogo}>
                    {!hasWixParent && (
                        <a href="https://us.fsc.org">
                            <img src={logo} alt="FSC LOGO" />
                        </a>
                    )}
                    {hasWixParent && (
                        <button
                            onClick={() => {
                                window.parent.postMessage(
                                    {
                                        externalSite: "https://us.fsc.org",
                                    },
                                    "*",
                                );
                            }}
                        >
                            <img src={logo} alt="FSC LOGO" width="25px" />
                        </button>
                    )}
                </div>
                <div className={styles.nnrgLogo}>
                    {!hasWixParent && (
                        <a href="https://nnrg.org">
                            <img src={nnrgLogo} alt="NNRG LOGO" width="120px" />
                        </a>
                    )}
                    {hasWixParent && (
                        <button
                            onClick={() => {
                                window.parent.postMessage(
                                    {
                                        externalSite: "https://nnrg.org",
                                    },
                                    "*",
                                );
                            }}
                        >
                            <img src={nnrgLogo} alt="NNRG LOGO" width="120px" />
                        </button>
                    )}
                </div>
            </div>
        </footer>
    );
};

export default Footer;

import React, { useState } from "react";
import Modal from "react-modal";
import styles from "./index.module.scss";
import StyledSelect from "../styledSelect";
import EmailSupplierModal from "./email-supplier-modal";
import ShareModal from "./share-modal";
import logo from "../../assets/images/header_FSClogo_25px_wide.png";
import { useTranslation } from "react-i18next";

const Header = (props) => {
    const {
        showWelcomeScreen,
        searchResults = [],
        affiliate,
        searchUUID,
        hasWixParent,
        parentURL,
    } = props;

    const [emailOpen, setEmailOpen] = useState(false);
    const [shareOpen, setShareOpen] = useState(false);

    const { t, i18n } = useTranslation();

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
    };

    Modal.setAppElement("#root");

    const innerWelcome = (
        <header>
            <div className={styles.headerHome}>
                {!hasWixParent && (
                    <a href={parentURL}>
                        <img src={logo} alt="FSC LOGO" width="25px" />
                    </a>
                )}
                {hasWixParent && (
                    <button
                        onClick={() => {
                            window.parent.postMessage(
                                {
                                    command: "redirectToWelcome",
                                },
                                "*",
                            );
                        }}
                    >
                        <img src={logo} alt="FSC LOGO" width="25px" />
                    </button>
                )}

                <StyledSelect
                    options={["EN", "ES", "FR"]}
                    handleChange={changeLanguage}
                    value={i18n.language}
                />
            </div>
            <p className={styles.headerTitle}>
                {t("FSC® Supplier Search")} &mdash; {t("North America")}
            </p>
            {!showWelcomeScreen && (
                <div className={styles.headerActions}>
                    <button className={styles.print} onClick={window.print}>
                        <span></span>
                    </button>
                    <button
                        className={styles.email}
                        onClick={() => {
                            setEmailOpen(true);
                        }}
                    >
                        <span></span>
                    </button>
                    <button
                        className={styles.share}
                        onClick={() => {
                            setShareOpen(true);
                        }}
                    >
                        <span></span>
                    </button>

                    <EmailSupplierModal
                        searchResults={searchResults}
                        emailOpen={emailOpen}
                        setEmailOpen={setEmailOpen}
                        hasWixParent={hasWixParent}
                    />
                    <ShareModal
                        shareOpen={shareOpen}
                        setShareOpen={setShareOpen}
                        affiliate={affiliate}
                        searchUUID={searchUUID}
                        parentURL={parentURL}
                    />
                </div>
            )}
        </header>
    );
    return (
        <>
            {!showWelcomeScreen && innerWelcome}
            {showWelcomeScreen && <div className={styles.innerWelcomeWrapper}>{innerWelcome}</div>}
        </>
    );
};

export default Header;

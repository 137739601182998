// @flow
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

type Props = { typeList: string[], setBuyerType: Function, value: string, buyerType: string };

const BuyerTypeButton = (props: Props) => {
    const { typeList, searchTerms, setSearchTerms, value } = props;
    const { buyerType } = searchTerms;
    const { t } = useTranslation();
    const [thisOptionSelected, setThisOptionSelected] = useState(Boolean(value === buyerType));

    useEffect(() => {
        setThisOptionSelected(Boolean(value === buyerType));
    }, [buyerType, value]);

    return (
        <button
            onClick={() => {
                setSearchTerms({ ...searchTerms, buyerType: value });
            }}
            className={`${styles.button} ${thisOptionSelected ? styles.selected : ""}`}
        >
            <ul>
                {typeList.map((item) => (
                    <li key={item}>{t(item)}</li>
                ))}
            </ul>
        </button>
    );
};

export default BuyerTypeButton;

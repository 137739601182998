// @flow
import React, { useState, useEffect } from "react";
import type { LocationType } from "../../../types";
import FSCLogo from "../../../assets/images/FSC-logo.png";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

type Props = { location: LocationType, idx: Number, searchTerms: Object, hasWixParent: boolean };

const PrintLocation = (props: Props) => {
    const { location, searchTerms } = props;
    const [distanceText, setDistanceText] = useState("");
    const { t } = useTranslation();

    useEffect(() => {
        if (searchTerms && location && location.distance) {
            let tempDistanceText = "";
            if (searchTerms.postalCodeCountry) {
                const units: string = searchTerms.postalCodeCountry === "US" ? " miles" : "km";
                const conversion: number = units === "mi" ? 1.609344 : 1;
                tempDistanceText = location.distance
                    ? Math.round((location.distance / 1000) * conversion)
                          .toString()
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
                      units +
                      " from " +
                      searchTerms.postalCode
                    : "";
            } else {
                tempDistanceText = "";
            }
            setDistanceText(tempDistanceText);
        }
    }, [searchTerms, location]);

    return (
        <li
            key={location.id}
            className={`${styles["location"]} ${location.fscMember ? styles.fscMember : ""}`}
        >
            <h2>{location.name}</h2>
            <h3>{location.title}</h3>
            {distanceText && searchTerms.postalCodeCountry && (
                <p className={styles.proximity}>{distanceText}</p>
            )}
            {location.fscMember && (
                <>
                    <p className={styles["fsc-certified-label"]}>{t("FSC Member")}</p>
                    <img
                        className={styles["fsc-certified"]}
                        alt="FSC Logo denoting membership"
                        src={FSCLogo}
                    />
                </>
            )}
            {Boolean(location.products.length) && (
                <ul className={styles["card-products"]}>
                    {location.products.map((product) => (
                        <li key={product.id}>
                            <p className={styles["product-name"]}>
                                {t(product.name)}
                                &nbsp;
                                <span className={styles.availability}>
                                    ({t(product.availability)})
                                </span>
                            </p>
                        </li>
                    ))}
                </ul>
            )}
            {Boolean(location.species.length) && (
                <p className={styles["supplier-types"]}>
                    <span className={styles["supplier-types-label"]}>{t("Species")}: </span>
                    {location.species}
                </p>
            )}
            {Boolean(location.supplierTypes.length && location.supplierTypes.includes(",")) && (
                <p className={styles["supplier-types"]}>
                    <span className={styles["supplier-types-label"]}>{t("Supplier Types")}: </span>
                    {location.supplierTypes}
                </p>
            )}
            {Boolean(location.supplierTypes.length && !location.supplierTypes.includes(",")) && (
                <p className={styles["supplier-types"]}>
                    <span className={styles["supplier-types-label"]}>{t("Supplier Type")}: </span>
                    {location.supplierTypes}
                </p>
            )}
            <p className={styles["contact-name"]}>{location.contactName}</p>
            <p className={styles["contact-number"]}>{location.contactPhone}</p>
            <div className={styles.printLinks}>
                <p>{location.contactEmail}</p>
                <span className={styles.divider}></span>
                <p>{location.webAddress}</p>
            </div>
            <p className={styles.address}>
                {location.streetAddress1}
                {location.streetAddress2 && (
                    <>
                        <br /> {location.streetAddress2}
                    </>
                )}
                <br />
                {location.city}, {location.provinceOrState} {location.postalCode} {location.country}
            </p>
            <span className={styles["certified-since"]}>
                {t("Certified since")} {location.certifiedSince}
            </span>
        </li>
    );
};

export default PrintLocation;

// @flow
import React from "react";
import { useTranslation } from "react-i18next";
import StyledRadio from "../../styledRadio";
import styles from "./index.module.scss";

type Props = { setBuyerType: Function, buyerType: string };

const BuyerType = (props: Props) => {
    const { buyerType, setBuyerType } = props;
    const { t } = useTranslation();
    return (
        <>
            <ul className={styles["category-list"]}>
                <li>
                    <StyledRadio
                        label={["Manufacturer", "Distributor", "Retailer"]
                            .map((item) => t(item))
                            .join(" / ")}
                        name="buyer-type"
                        id="buyer-type-A"
                        onChange={() => setBuyerType("wholesale")}
                        checked={buyerType === "wholesale"}
                        value={buyerType}
                    />
                </li>
                <li>
                    <StyledRadio
                        label={["AEC Professional"].map((item) => t(item)).join(" / ")}
                        name="buyer-type"
                        id="buyer-type-B"
                        onChange={() => setBuyerType("contractor")}
                        checked={buyerType === "contractor"}
                        value={buyerType}
                    />
                </li>
                <li>
                    <StyledRadio
                        label={["Homeowner", "DIYer"].map((item) => t(item)).join(" / ")}
                        name="buyer-type"
                        id="buyer-type-C"
                        onChange={() => setBuyerType("retail")}
                        checked={buyerType === "retail"}
                        value={buyerType}
                    />
                </li>
            </ul>
        </>
    );
};

export default BuyerType;

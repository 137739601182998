// @flow
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import type {
    SectionsType,
    ProductsType,
    SelectedProductsType,
    BuyerTypeType,
    LocationsType,
} from "../../types";
import WelcomeBuyerType from "./welcome-buyer-type";
import Products from "../search-filter/products";
import styles from "./index.module.scss";
import StyledButton from "../styledButton";
import Tooltip from "../tooltip/index";
import Proximity from "../search-filter/proximity";

type Props = {
    sections: SectionsType,
    searchTerms: Object,
    setSearchTerms: (Object) => void,
    setSelectedProducts: (ProductsType) => void,
    selectedProducts: SelectedProductsType,
    isFetchingSearchResults: boolean,
    setIsFetchingSearchResults: (boolean) => void,
    setSearchResults: (LocationsType) => void,
    setShowWelcomeScreen: (boolean) => void,
    buyerType: BuyerTypeType,
    setBuyerType: (BuyerTypeType) => void,
};

const Welcome = (props: Props) => {
    const {
        setVisitedWelcome,
        sections,
        searchTerms,
        setSearchTerms,
        isFetchingSearchResults,
        // setIsFetchingSearchResults,
        // setSearchResults,
        setShowWelcomeScreen,
    } = props;
    // const { t } = useTranslation();
    const [welcomeSearchDisabled, setWelcomeSearchDisabled] = useState(true);
    const { t } = useTranslation();
    const [toolTipShowing, setToolTipShowing] = useState(false);
    useEffect(() => {
        if (
            searchTerms.selectedProducts &&
            searchTerms.selectedProducts.length > 0 &&
            searchTerms.buyerType !== "" &&
            searchTerms.postalCode !== "" &&
            isFetchingSearchResults === false
        ) {
            setWelcomeSearchDisabled(false);
        } else {
            setWelcomeSearchDisabled(true);
        }
    }, [searchTerms, isFetchingSearchResults]);

    useEffect(() => {
        setVisitedWelcome(true);
    }, [setVisitedWelcome]);

    const welcomeSearch = async () => {
        setShowWelcomeScreen(false);
    };

    return (
        <div className={styles.content}>
            <div id={styles["welcome-content"]}>
                <div className={styles["welcome-box"]}>
                    <div className={styles["buyer-type"]}>
                        <h1>
                            <span>{t("Welcome! What type of buyer are you?")}</span>
                            <Tooltip
                                style={{
                                    position: "absolute",
                                    display: "inline-block",
                                    marginLeft: "5px",
                                }}
                                field="buyerType"
                            />
                        </h1>

                        <WelcomeBuyerType
                            searchTerms={searchTerms}
                            setSearchTerms={setSearchTerms}
                        />
                    </div>

                    <Products
                        sections={sections}
                        searchTerms={searchTerms}
                        setSearchTerms={setSearchTerms}
                    />

                    <div className={styles["proximity"]}>
                        <h1>
                            {" "}
                            <span>{t("Where do you need them?")}</span>
                            <Tooltip
                                style={{ position: "absolute", top: "-5px", right: "-25px" }}
                                field="proximity"
                                toolTipShowing={toolTipShowing}
                                setToolTipShowing={setToolTipShowing}
                            />
                        </h1>

                        <Proximity searchTerms={searchTerms} setSearchTerms={setSearchTerms} />
                    </div>

                    <StyledButton
                        onClick={welcomeSearch}
                        disabled={welcomeSearchDisabled}
                        containerStyle={styles.search}
                    >
                        {t("Search")}
                    </StyledButton>
                </div>
            </div>
        </div>
    );
};

export default Welcome;

// @flow
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import type { SectionsType, ProductsType } from "../../../types";
import Select from "react-select";
import { getProductOptions, getProductList, getProductNameById } from "../../../lib/helpers";
import styles from "./index.module.scss";
import StyledCheck from "../../styledCheck";
import { useWindowDimensions } from "../../../lib/helpers";

type Props = {
    sections: SectionsType,
    setSearchTerms: (Object) => void,
    searchTerms: Object,
    showHeader: boolean,
    showSearchIcon: boolean,
};

const Products = (props: Props) => {
    const {
        sections,
        searchTerms,
        setSearchTerms,
        showHeader = true,
        showSearchIcon = true,
    } = props;
    const [productOptions, setProductOptions] = useState([]);
    const [openCategory, setOpenCategory] = useState();
    const [openAccordian, setOpenAccordian] = useState(false);
    const { t, i18n } = useTranslation();
    const { height, width } = useWindowDimensions();

    const placeHolderText = showSearchIcon
        ? t("Product name or category...")
        : t("Search products...");

    const formatOptionLabel = ({ value, label }) => {
        const labelParts = label.split(">");

        if (value === "") {
            return placeHolderText;
        } else {
            return (
                <>
                    {labelParts[0]} > {labelParts[1]} >{" "}
                    <span className={styles.selectProduct}>{labelParts[2]}</span>
                </>
            );
        }
    };

    const customStyles = {
        container: (provided, state) => ({
            display: "flex",
            flexDirection: "column",
            border: "1px solid #cacccd",
            borderBottomRightRadius: "5px",
            borderTopRightRadius: "5px",
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
            borderLeft: 0,
            fontSize: width <= 768 ? "16px" : "15px",
            fontWeight: 500,
            height: "42px",
            width: "100%",
            padding: showSearchIcon ? "0 10px 0 44px" : "0 10px 0 5px",
            backgroundColor: "#fff",
        }),
        control: (provided) => ({
            position: "relative",
            display: "flex",
            flexWrap: "wrap",
            border: 0,
            cursor: "default",
            minHeight: "40px",
        }),
        valueContainer: (provided) => ({
            ...provided,
            display: "flex",
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#ccc",
        }),
        indicatorSeparator: () => ({
            display: "none",
        }),
        dropdownIndicator: () => ({
            display: "none",
        }),
        input: (provided) => ({
            ...provided,
        }),
        menu: (provided) => ({
            ...provided,
            left: "-115px",
            width: "calc(100% + 115px)",
        }),
    };

    if (width < 310) {
        customStyles["placeholder"] = (provided, state) => ({
            ...provided,
            color: "#ccc",
            fontSize: "smaller",
        });
    }

    useEffect(() => {
        if (sections.length > 0) {
            setProductOptions(getProductOptions(sections, searchTerms, t));
        }
    }, [sections, searchTerms, i18n.language, t]);

    const handleSelectChange = (arg: { name: string, value: number }) => {
        if (!searchTerms.selectedProducts.includes(arg.value)) {
            setSearchTerms({
                ...searchTerms,
                selectedProducts: [...searchTerms.selectedProducts, arg.value],
                fromSearchUID: false,
            });
        }
    };

    const toggleProduct = (value) => {
        if (searchTerms.selectedProducts.includes(value)) {
            setSearchTerms({
                ...searchTerms,
                selectedProducts: searchTerms.selectedProducts.filter(
                    (selectedProduct) => selectedProduct !== value,
                ),
                fromSearchUID: false,
            });
        } else {
            setSearchTerms({
                ...searchTerms,
                selectedProducts: [...searchTerms.selectedProducts, value],
                fromSearchUID: false,
            });
        }
    };

    const productList: ProductsType = getProductList(sections);

    const deleteSelectedProduct = (id: number) => {
        const idx = searchTerms.selectedProducts.indexOf(id);
        if (idx !== -1) {
            let tempSelectedProducts = [...searchTerms.selectedProducts];

            tempSelectedProducts.splice(idx, 1);
            setSearchTerms({
                ...searchTerms,
                selectedProducts: tempSelectedProducts,
                fromSearchUID: false,
            });
        }
    };

    const toggleCategory = (category) => {
        if (category === openCategory) {
            setOpenCategory(null);
        } else {
            setOpenCategory(category);
        }
    };

    return (
        <div className={`${showHeader ? styles.addProducts : ""}`}>
            {showHeader && <h2>{t("What products do you need?")}</h2>}
            {searchTerms.selectedProducts.length > 0 && (
                <ul className={styles.selectedProducts}>
                    {searchTerms.selectedProducts.map((id) => (
                        <li key={id}>
                            {t(getProductNameById(id, productList))}&nbsp;
                            <button
                                onClick={() => deleteSelectedProduct(id)}
                                className={styles.close}
                            ></button>
                        </li>
                    ))}
                </ul>
            )}
            <div className={`${styles.accordionSearch}`}>
                <div className={styles.search}>
                    <button
                        className={openAccordian ? styles.open : {}}
                        onClick={() => setOpenAccordian(!openAccordian)}
                    >
                        {t("Products")}
                        <span className={styles.chevron}></span>
                    </button>
                    <div className={styles.searchWrapper}>
                        {showSearchIcon && (
                            <svg
                                focusable="false"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M20.49,19l-5.73-5.73C15.53,12.2,16,10.91,16,9.5C16,5.91,13.09,3,9.5,3S3,5.91,3,9.5C3,13.09,5.91,16,9.5,16 c1.41,0,2.7-0.47,3.77-1.24L19,20.49L20.49,19z M5,9.5C5,7.01,7.01,5,9.5,5S14,7.01,14,9.5S11.99,14,9.5,14S5,11.99,5,9.5z"></path>
                            </svg>
                        )}

                        <Select
                            formatOptionLabel={formatOptionLabel}
                            placeholder={placeHolderText}
                            options={productOptions}
                            name="product"
                            classNamePrefix="product"
                            styles={customStyles}
                            searchable={true}
                            clearable={true} // none of this appears to be working
                            backspaceRemoves={true} // none of this appears to be working
                            deleteRemoves={true} // none of this appears to be working
                            escapeRemoves={true} // none of this appears to be working
                            onChange={handleSelectChange}
                            value={""}
                        />
                    </div>
                </div>

                {openAccordian && (
                    <ul className={styles.productAccordion}>
                        {sections.map((section) => (
                            <li
                                key={section.name}
                                className={openCategory === section.name ? styles.open : ""}
                            >
                                <div
                                    className={styles.accordionHeader}
                                    onClick={() => toggleCategory(section.name)}
                                >
                                    <span className={styles.chevron}></span>
                                    {t(section.name)}
                                </div>
                                <ul className={styles.subcategories}>
                                    {section.categories
                                        .filter((category) => category.products.length > 0) //necessary if we are restricting products by availability
                                        .map((category) => (
                                            <li key={`category-${category.id}`}>
                                                <span className={styles.subcategoryTitle}>
                                                    {t(category.name)}
                                                </span>
                                                <ul className={styles.products}>
                                                    {category.products.map((product) => (
                                                        <li key={`product-${product.id}`}>
                                                            <StyledCheck
                                                                id={product.id}
                                                                label={t(product.name)}
                                                                onChange={() =>
                                                                    toggleProduct(product.id)
                                                                }
                                                                checked={searchTerms.selectedProducts.includes(
                                                                    product.id,
                                                                )}
                                                            />
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        ))}
                                </ul>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default Products;

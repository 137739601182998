import React, { memo, useEffect, useRef } from "react";
import ReactMapboxGl, { Cluster, Marker, ZoomControl } from "react-mapbox-gl";
import { LngLat, LngLatBounds } from "mapbox-gl";
import { throttle } from "lodash";

const Map = ReactMapboxGl({
    accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
    doubleClickZoom: true,
});
const mapStyle = "mapbox://styles/mapbox/streets-v9";

const clusterStyle = {
    backgroundColor: "#003865", // primary-dark-blue
    width: 40,
    height: 40,
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "1px 1px 5px 1px rgba(0,0,0,0.4)",
    color: "white",
    fontSize: "1.2rem",
    cursor: "pointer",
    pointerEvents: "none",
    userSelect: "none",
};

const defaultCenter = new LngLat(-101.3002, 47.108);
const defaultBounds = new LngLatBounds();
defaultBounds.extend(defaultCenter);

const MapView = (props) => {
    const {
        searchResults,
        setCurrentExtent,
        selectedResult,
        setIsMoving,
        setIsZooming,
        setPage,
        searchTerms,
        setMapInteracted,
        searchCenter,
        mapRef,
    } = props;

    const prevPostalCode = useRef({ current: searchTerms.postalCode });
    const prevSearchResults = useRef({ current: searchResults });

    const clusterMarker = (coordinates, pointCount, getLeaves) => {
        return (
            <Marker key={coordinates.toString()} coordinates={coordinates} style={clusterStyle}>
                {pointCount}
            </Marker>
        );
    };

    const handleMoveEnd = (map) => {
        setCurrentExtent(map.getBounds());
        setIsMoving(false);

        // console.log("move end");
    };

    const handleDragStart = (map) => {
        // setIsMoving(true);
        setPage(0);

        setMapInteracted(true);
    };

    const handleZoomStart = (map) => {
        // setIsMoving(true);
        // setIsZooming(true);
        setPage(0);

        setMapInteracted(true);
        // console.log("zoom start");
    };

    const handleZoomEnd = (map) => {
        // console.log("zoom end");
        setIsZooming(false);
    };

    const selected = searchResults.find((result) => result.id === selectedResult);

    useEffect(() => {
        // console.log("map.js useEffect: ");
        if (
            (prevPostalCode.current !== searchTerms.postalCode ||
                prevSearchResults.current !== searchResults) &&
            searchResults.length &&
            mapRef &&
            mapRef.current &&
            mapRef.current.state &&
            mapRef.current.state.map
        ) {
            const bounds = new LngLatBounds();
            const results = searchResults.filter((result) => result.longitude && result.latitude);

            // compromise line allowing members to be used only if they are inside window
            results.sort((a, b) => a.distance - b.distance);
            if (searchTerms.postalCode) {
                results.slice(0, 12).forEach((result) => {
                    bounds.extend(new LngLat(result.longitude, result.latitude));
                });
            } else {
                results.forEach((result) => {
                    bounds.extend(new LngLat(result.longitude, result.latitude));
                });
            }

            const ne = bounds.getNorthEast();
            const sw = bounds.getSouthWest();
            if (ne !== undefined && sw !== undefined) {
                let east = ne.lng;
                let west = sw.lng;
                let north = ne.lat;
                let south = sw.lat;
                if (searchCenter) {
                    // console.log("before math", north, east, south, west);

                    const northDiff = Math.abs(searchCenter.latitude - north);
                    const southDiff = Math.abs(searchCenter.latitude - south);
                    const latDiff = northDiff > southDiff ? northDiff : southDiff;
                    // console.log("latDiff", latDiff);
                    const westDiff = Math.abs(searchCenter.longitude - west);
                    const eastDiff = Math.abs(searchCenter.longitude - east);
                    const lonDiff = westDiff > eastDiff ? westDiff : eastDiff;
                    // console.log("lonDiff", lonDiff);

                    east = searchCenter.longitude + lonDiff;
                    west = searchCenter.longitude - lonDiff;
                    north = searchCenter.latitude + latDiff;
                    south = searchCenter.latitude - latDiff;
                    // console.log("after math", north, east, south, west);
                }
                bounds.setNorthEast(new LngLat(east, north));
                bounds.setSouthWest(new LngLat(west, south));
            }

            mapRef.current.state.map.fitBounds(bounds, { padding: 30 });
        }
    }, [searchTerms.postalCode, searchResults, mapRef, searchCenter]);

    useEffect(() => {
        if (mapRef.current.state.map) {
            mapRef.current.state.map.defaultCenter = new LngLat(-101.3002, 47.108);
            console.log("************** setting default Center ****************");
        }
    }, [mapRef]);

    return (
        <Map
            style={mapStyle}
            ref={(map) => {
                mapRef.current = map;
            }}
            onMoveEnd={throttle(handleMoveEnd, 250, { trailing: true })}
            // onMoveStart={throttle(handleMoveStart, 1000, { trailing: true })}
            onDragStart={handleDragStart}
            onZoomStart={handleZoomStart}
            onZoomEnd={handleZoomEnd}
            containerStyle={{
                height: "100%",
                width: "100%",
            }}
            onData={(map) => {
                map.resize();
            }}
            center={
                mapRef.current && mapRef.current.state && mapRef.current.state.map
                    ? mapRef.current.state.map.center
                    : searchCenter
                    ? [searchCenter.longitude, searchCenter.latitude]
                    : [-101.3002, 47.108]
            }
            zoom={
                mapRef.current && mapRef.current.state && mapRef.current.state.map
                    ? mapRef.current.state.map.zoom
                    : [2]
            }
        >
            <ZoomControl />
            <Cluster ClusterMarkerFactory={clusterMarker}>
                {searchResults
                    .filter((result) => {
                        if (result.id === selectedResult) return false;
                        return result.latitude && result.longitude;
                    })
                    .map((marker, key) => (
                        <Marker
                            key={key}
                            coordinates={[marker.longitude, marker.latitude]}
                            style={{ pointerEvents: "none" }}
                        >
                            <div
                                style={{
                                    width: "20px",
                                    height: "20px",
                                    borderRadius: "10px",
                                    backgroundColor: "#003865",
                                    pointerEvents: "none",
                                    userSelect: "none",
                                }}
                            />
                        </Marker>
                    ))}
            </Cluster>
            {selectedResult && (
                <Marker
                    coordinates={[selected.longitude, selected.latitude]}
                    style={{ pointerEvents: "none" }}
                >
                    <div
                        style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "10px",
                            backgroundColor: "orange",
                            pointerEvents: "none",
                            userSelect: "none",
                        }}
                    />
                </Marker>
            )}
        </Map>
    );
};

export default memo(MapView);

import React from "react";
import styles from "./index.module.scss";

const StyledCheck = (props) => {
    const { checked, id, label, onChange } = props;
    return (
        <div className={styles["styled-checkbox"]}>
            <input
                type="checkbox"
                id={`check-${id}-${label}`}
                onChange={onChange}
                checked={checked}
            />
            <label htmlFor={`check-${id}-${label}`}>{label}</label>
        </div>
    );
};

export default StyledCheck;

import React from "react";
import styles from "./index.module.scss";

const StyledButton = (props) => {
    const { children, disabled, containerStyle = {}, onClick } = props;

    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={`${styles.button} ${containerStyle}`}
        >
            {children}
        </button>
    );
};

export default StyledButton;

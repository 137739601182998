import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import config from "./config";

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: "en",
        debug: false, //process.env.NODE_ENV !== "production",
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            bindI18n: "languageChanged",
        },
        keySeparator: false,
        backend: {
            loadPath: `${config.apiRoot}/translation/{{lng}}/`,
            customHeaders: {
                "content-type": "application/json",
                // the following line causes CORS errors.
                //contentType: 'application/json',
            },
        },
    });

export default i18n;

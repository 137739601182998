import React from "react";
import styles from "./index.module.scss";

const StyledSelect = (props) => {
    const { options, handleChange, value = null } = props;

    return (
        <select
            className={styles.styledSelect}
            onChange={(e) => {
                handleChange(e.target.value.toLowerCase());
            }}
            defaultValue={value.toUpperCase()}
        >
            {options.map((option) => (
                <option key={option} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
};

export default StyledSelect;
